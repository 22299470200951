<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <ValidationObserver v-slot="{ invalid, validated }">
      <v-navigation-drawer v-model="showCreateEdit" app clipped right width="600">
        <template v-slot:prepend>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title v-if="id" class="title"> Edit </v-list-item-title>
              <v-list-item-title v-else class="title"> New </v-list-item-title>
              <v-list-item-subtitle>Commit</v-list-item-subtitle>
            </v-list-item-content>
            <v-btn
              icon
              color="info"
              :loading="loading"
              :disabled="invalid || !validated"
              @click="save()"
            >
              <v-icon>save</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="closeCreateEdit">
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-item>
        </template>
      </v-navigation-drawer>
    </ValidationObserver>
  </template>

  <script>
  import { mapFields } from "vuex-map-fields"
  import { mapActions } from "vuex"
  import { ValidationObserver } from "vee-validate"


  export default {
    name: "CommitNewEditSheet",

    components: {
      ValidationObserver,
    },

    computed: {
      ...mapFields("commit", ["selected.id", "selected.loading", "dialogs.showCreateEdit"]),
    },

    methods: {
      ...mapActions("commit", ["closeCreateEdit"]),
    },
  }
  </script>
